html,
body,
div#root {
  height: 100%;
}

.pageNotFound {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 25px;
}
.pageNotFound button {
  margin-top: 20px;
  font-size: 15px;
  border: 1px solid black;
  border-radius: 3px;
}
.nav-link.active {
  color: white !important;
}

main {
  height: 100%!important;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

main {
  height: 100vh;
  height: -webkit-fill-available;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, 0.1);
  border: solid rgba(0, 0, 0, 0.15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgba(0, 0, 0, 0.1),
    inset 0 0.125em 0.5em rgba(0, 0, 0, 0.15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1rem;
  height: 100vh;
}

.bi {
  vertical-align: -0.125em;
  fill: currentColor;
}

.main-content {
  background-color: #f8f9fa;
}

.pointer {
  cursor: pointer;
}

.itemsViewHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}
.itemsViewHeader .buttons {
  display: flex;
  gap: 10px;
}

.form-container {
  padding: 10px;
  border-top: 1px solid #c1c1c1;
  border-bottom: 1px solid #c1c1c1;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #c1c1c1;
  border-top: 1px solid #c1c1c1;
  background-color: #ffffff;

  margin-top: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.form-container .itemsViewHeader {
  padding-bottom: 0px;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: white;
  border: 1px solid #c1c1c1;
  border-bottom: 0px;
}

.container .nav-link.active {
  color: black !important;
}

.language-switcher {
  text-align: center;
}

.signout {
  text-align: center;
}

.bottom_menu_links {
  text-align: center;
}

.menu_link {
  text-align: center;
  display: block !important;
  width: 100%;
}

.file-upload-progress {
  width: 60px;
  color: white !important;
  background-color: black !important;
  font-weight: bold !important;
}

input[type="file"]:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}

/*
  Landing
*/

.connectButton {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: #3898ff;
  transition: 0.125s ease;
  position: relative;
  display: block;
  padding-left: 14px;
  padding-right: 14px;
  height: 40px;
  word-spacing: 0px;
  font-weight: 700;
  font-size: 16px;
  font-family: SFRounded, ui-rounded, "SF Pro Rounded", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  border-radius: 12px;
  cursor: pointer;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  appearance: none;
  color: #fff;
  border-style: none;
  border-width: 0;
}

.connectButton:hover,
.connectButton:active {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  background: #53a6ff;
}

.connectButton.invalid {
  background: red;
}

.connectButton:disabled {
  background-color: #4986c7;
}

.landingContainer {
  background-image: url("https://zombiespfps.space/assets/images/Back_2.png");
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
}

.zombie {
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-height: 100vh;
  max-width: 100vw;
}

.landingInfo {
  padding: 15px;
  background-color: rgb(0 0 0 / 60%);
  border-radius: 12px;
  text-align: center;
  color: white;
  z-index: 2;
}

.landingInfo h1 {
  padding-bottom: 10px;
  display: block;
}

.connectContainer {
  display: inline-block;
}

.landingLinks {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.landingLinks a {
  color: white;
}

/*
  Modal
*/
.ReactModalPortal {
  z-index: 9999999;
  position: absolute;
}
.ReactModal__Content {
  max-width: 60%;
  transition: 1.125s ease;
}

.ReactModal__Content .close {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 10px;
  margin-right: 10px;
  border-radius: 299px;
  border: 1px solid black;
  background-color: #c1c1c126;
  padding: 2px;
  width: 30px;
  text-align: center;
  height: 30px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 2px 8px rgb(0 0 0 / 8%);
}

.mintSuccess {
  background-color: #00000073;
  border-radius: 5px;
  padding: 5px;
  margin-top: 15px;
}

.mintSuccess a {
  color: white;
}

.nav-link:hover {
  text-decoration: underline;
}

.file-item {
  margin-top: 5px;
  border-bottom: 1px solid #c1c1c1;
  padding-bottom: 5px;
}

.delete-item {
  padding-right: 10px;
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}