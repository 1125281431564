@media (max-width: 800px) {
  .main-content {
    width: 100% !important;
    height: 100vh;

    margin-top: 5px;
    border-top: 2px solid #c1c1c1;
  }

  main {
    flex-direction: column;
    height: 100%;
    overflow: scroll;
  }

  main .bg-light {
    width: 100% !important;
  }

  .b-example-vr {
    display: none;
  }

  .menu-top {
    display: none!important;
  }

  .signout {
    padding: 0px;
  }
}

@media (min-width: 800px) {
  main {
    overflow: scroll;
  }
}