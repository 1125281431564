.modal,
.confirm-modal,
.response-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content,
.confirm-content,
.response-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 500px;
    width: 100%;
}

.modal-content h3,
.response-content h3 {
    margin-bottom: 15px;
}

textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
}

button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin: 5px;
}

button:disabled {
    background: #ccc;
    cursor: not-allowed;
}

button:hover:enabled {
    background: #0056b3;
}

.error {
    color: red;
    margin-top: 10px;
}